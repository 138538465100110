module.exports=[
  {
    "Home #": 1402,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 969,
    "Level": 14
  },
  {
    "Home #": 1502,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 969,
    "Level": 15
  },
  {
    "Home #": 1602,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 969,
    "Level": 16
  },
  {
    "Home #": 1702,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 969,
    "Level": 17
  },
  {
    "Home #": 501,
    "Home Type": "Two Bedroom",
    "Total Sq Ft": 1440,
    "Level": 5
  },
  {
    "Home #": 502,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 653,
    "Level": 5
  },
  {
    "Home #": 601,
    "Home Type": "Two Bedroom",
    "Total Sq Ft": 1317,
    "Level": 6
  },
  {
    "Home #": 602,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 854,
    "Level": 6
  },
  {
    "Home #": 603,
    "Home Type": "One Bedroom & Atelier",
    "Total Sq Ft": 1041,
    "Level": 6
  },
  {
    "Home #": 604,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 615,
    "Level": 6
  },
  {
    "Home #": 605,
    "Home Type": "Two Bedroom & Atelier",
    "Total Sq Ft": 1316,
    "Level": 6
  },
  {
    "Home #": 606,
    "Home Type": "One Bedroom & Atelier",
    "Total Sq Ft": 738,
    "Level": 6
  },
  {
    "Home #": 607,
    "Home Type": "Two Bedroom",
    "Total Sq Ft": 1056,
    "Level": 6
  },
  {
    "Home #": 701,
    "Home Type": "Two Bedroom",
    "Total Sq Ft": 1377,
    "Level": 7
  },
  {
    "Home #": 702,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 869,
    "Level": 7
  },
  {
    "Home #": 703,
    "Home Type": "One Bedroom & Atelier",
    "Total Sq Ft": 1086,
    "Level": 7
  },
  {
    "Home #": 704,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 612,
    "Level": 7
  },
  {
    "Home #": 705,
    "Home Type": "Two Bedroom & Atelier",
    "Total Sq Ft": 1358,
    "Level": 7
  },
  {
    "Home #": 706,
    "Home Type": "One Bedroom & Atelier",
    "Total Sq Ft": 738,
    "Level": 7
  },
  {
    "Home #": 707,
    "Home Type": "Two Bedroom",
    "Total Sq Ft": 1130,
    "Level": 7
  },
  {
    "Home #": 801,
    "Home Type": "Two Bedroom",
    "Total Sq Ft": 1437,
    "Level": 8
  },
  {
    "Home #": 802,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 881,
    "Level": 8
  },
  {
    "Home #": 803,
    "Home Type": "One Bedroom & Atelier",
    "Total Sq Ft": 1126,
    "Level": 8
  },
  {
    "Home #": 804,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 620,
    "Level": 8
  },
  {
    "Home #": 805,
    "Home Type": "Two Bedroom & Atelier",
    "Total Sq Ft": 1366,
    "Level": 8
  },
  {
    "Home #": 806,
    "Home Type": "One Bedroom & Atelier",
    "Total Sq Ft": 738,
    "Level": 8
  },
  {
    "Home #": 807,
    "Home Type": "Two Bedroom",
    "Total Sq Ft": 1130,
    "Level": 8
  },
  {
    "Home #": 901,
    "Home Type": "Two Bedroom & Atelier",
    "Total Sq Ft": 1514,
    "Level": 9
  },
  {
    "Home #": 902,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 899,
    "Level": 9
  },
  {
    "Home #": 903,
    "Home Type": "One Bedroom & Atelier",
    "Total Sq Ft": 1172,
    "Level": 9
  },
  {
    "Home #": 904,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 620,
    "Level": 9
  },
  {
    "Home #": 905,
    "Home Type": "Two Bedroom & Atelier",
    "Total Sq Ft": 1366,
    "Level": 9
  },
  {
    "Home #": 906,
    "Home Type": "One Bedroom & Atelier",
    "Total Sq Ft": 738,
    "Level": 9
  },
  {
    "Home #": 907,
    "Home Type": "Two Bedroom",
    "Total Sq Ft": 1130,
    "Level": 9
  },
  {
    "Home #": 1001,
    "Home Type": "Two Bedroom & Atelier",
    "Total Sq Ft": 1663,
    "Level": 10
  },
  {
    "Home #": 1002,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 842,
    "Level": 10
  },
  {
    "Home #": 1003,
    "Home Type": "Two Bedroom",
    "Total Sq Ft": 1208,
    "Level": 10
  },
  {
    "Home #": 1004,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 620,
    "Level": 10
  },
  {
    "Home #": 1005,
    "Home Type": "Two Bedroom & Atelier",
    "Total Sq Ft": 1366,
    "Level": 10
  },
  {
    "Home #": 1006,
    "Home Type": "One Bedroom & Atelier",
    "Total Sq Ft": 738,
    "Level": 10
  },
  {
    "Home #": 1007,
    "Home Type": "Two Bedroom",
    "Total Sq Ft": 1130,
    "Level": 10
  },
  {
    "Home #": 1101,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 651,
    "Level": 11
  },
  {
    "Home #": 1102,
    "Home Type": "Two Bedroom",
    "Total Sq Ft": 1082,
    "Level": 11
  },
  {
    "Home #": 1103,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 841,
    "Level": 11
  },
  {
    "Home #": 1104,
    "Home Type": "Two Bedroom",
    "Total Sq Ft": 1214,
    "Level": 11
  },
  {
    "Home #": 1105,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 620,
    "Level": 11
  },
  {
    "Home #": 1106,
    "Home Type": "Two Bedroom & Atelier",
    "Total Sq Ft": 1366,
    "Level": 11
  },
  {
    "Home #": 1107,
    "Home Type": "One Bedroom & Atelier",
    "Total Sq Ft": 738,
    "Level": 11
  },
  {
    "Home #": 1108,
    "Home Type": "Two Bedroom",
    "Total Sq Ft": 1130,
    "Level": 11
  },
  {
    "Home #": 1201,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 651,
    "Level": 12
  },
  {
    "Home #": 1202,
    "Home Type": "Two Bedroom",
    "Total Sq Ft": 1116,
    "Level": 12
  },
  {
    "Home #": 1203,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 914,
    "Level": 12
  },
  {
    "Home #": 1204,
    "Home Type": "Two Bedroom",
    "Total Sq Ft": 1214,
    "Level": 12
  },
  {
    "Home #": 1205,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 620,
    "Level": 12
  },
  {
    "Home #": 1206,
    "Home Type": "Two Bedroom & Atelier",
    "Total Sq Ft": 1366,
    "Level": 12
  },
  {
    "Home #": 1207,
    "Home Type": "One Bedroom & Atelier",
    "Total Sq Ft": 739,
    "Level": 12
  },
  {
    "Home #": 1208,
    "Home Type": "Two Bedroom",
    "Total Sq Ft": 1130,
    "Level": 12
  },
  {
    "Home #": 1301,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 651,
    "Level": 13
  },
  {
    "Home #": 1302,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 921,
    "Level": 13
  },
  {
    "Home #": 1303,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 1228,
    "Level": 13
  },
  {
    "Home #": 1304,
    "Home Type": "Two Bedroom",
    "Total Sq Ft": 1214,
    "Level": 13
  },
  {
    "Home #": 1305,
    "Home Type": "One Bedroom",
    "Total Sq Ft": 621,
    "Level": 13
  },
  {
    "Home #": 1306,
    "Home Type": "Two Bedroom",
    "Total Sq Ft": 1243,
    "Level": 13
  },
  {
    "Home #": 1307,
    "Home Type": "One Bedroom & Atelier",
    "Total Sq Ft": 706,
    "Level": 13
  },
  {
    "Home #": 1308,
    "Home Type": "Two Bedroom",
    "Total Sq Ft": 1130,
    "Level": 13
  }
]
