'use strict'

import CustomBodymovin from './bodymovin'
import LoadingBM from '../bodymovin/Loading_1.1.json'
import st from './scrollto'

let self

export default self = {
  container : document.getElementById('mainHeader'),
  registerContainer : document.getElementById('register'),
  bmLogoContainer : document.getElementById('bmLogoContainer'),
  hamburger : document.getElementById('hamburger'),

  loadingBmObj : null,

  init : () => {
    self.setupElements()
    self.registerEvents()

    if ( !document.querySelector('body.home') ) {
      self.setupBodyMovin()
      self.loadingBmObj.play()
    }
  },

  setupElements : () => {
    self.registerLink = self.container.querySelector('#mainNav li:last-child a')
  },

  registerEvents : () => {
    self.registerLink.addEventListener("click", self.onClickRegister)
    self.hamburger.addEventListener("click", self.toggleMobileNav)
  },

  setupBodyMovin : () => self.loadingBmObj = new CustomBodymovin({
    elm : self.bmLogoContainer,
    data : LoadingBM
  }),

  onClickRegister : e => {
    if (self.registerContainer) {
      e.preventDefault()
      st.animate( window.innerHeight, 1000 )
      return false
    }
  },

  toggleMobileNav : e => self.container.classList.toggle("mobile-nav-open")
}
