/*
Name: Scroll To
Desc: Animates the scroll to a specific pixel value with a given duration.

Usage:
import st from './scrollto'
st.animate(element.offsetTop, 1000)

*/
let self

export default self = {
  animate: (to, duration) => {
    let start = document.documentElement.scrollTop + document.body.scrollTop,
      change = to - start,
      increment = 20

    let anim = elapsedTime => {
      elapsedTime += increment
      let position = self.easeInOut(elapsedTime, start, change, duration)

      document.documentElement.scrollTop = position
      document.body.scrollTop = position

      if (elapsedTime < duration) {
        setTimeout( () => {
          anim(elapsedTime)
        }, increment)
      }
    }

    anim(0)
  },

  easeInOut: (currentTime, start, change, duration) => {
    currentTime /= duration / 2
    if (currentTime < 1) {
      return change / 2 * currentTime * currentTime + start
    }
    currentTime -= 1
    return -change / 2 * (currentTime * (currentTime - 2) - 1) + start
  }
}
