'use strict'

let self

export default self = {
  container : document.querySelector('.overlay'),
  triggers : null,

  init : () => {
    self.setupElements()
    self.registerEvents()
  },

  setupElements : () => {
    self.triggers = document.querySelectorAll('.overlay-trigger')
  },

  registerEvents : () => {
    Array.from( self.triggers ).forEach( trigger => trigger.addEventListener("click", self.toggle))
  },

  toggle : e => {
    if (e) e.preventDefault()
    let id = e.currentTarget.getAttribute("rel")
    let overlay = document.getElementById(id)

    console.log(id, overlay);

    overlay.classList.toggle('show')
  }
}
