'use strict';

// Dependancies
import "babel-polyfill"

// Modules
import Overlay from './modules/overlay'
import Home from './modules/home'
import Register from './modules/register'
import Header from './modules/header'
import Accordion from './modules/accordion'
import InlineScrollNav from './modules/inlineScrollNav'
import Floorplans from './modules/floorplans'
import Slider from './modules/slider'


// Conditional Functionality
const o = [
  { m : Overlay, c : Overlay.container },
  { m : Home, c : Home.container },
  { m : Register, c : Register.container },
  { m : Header, c : Header.container },
  { m : Accordion, c : Accordion.container },
  { m : InlineScrollNav, c : InlineScrollNav.container },
  { m : Floorplans, c : Floorplans.container },
  { m : Slider, c : Slider.containers[0] }
]

// Check and run any conditional functionality
for (let r of o) if (r.c) r.m.init()
