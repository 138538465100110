'use strict'

import st from './scrollto'

let self

export default self = {
  container : document.getElementById('inlineScrollNav'),

  delta: 100,
  navIsShown : true,

  init : () => {
    self.setupElements()
    self.registerEvents()
    self.onScroll()
  },

  setupElements : () => {
    self.navItems = self.container.querySelectorAll('a')
  },

  registerEvents : () => {
    window.addEventListener("scroll", self.onScroll)
    Array.from( self.navItems ).forEach( button => button.addEventListener("click", self.scrollToId))
  },

  hideShownNav : () => {
    self.navIsShown = false

    Array.from( self.navItems ).forEach( (button, i) => {
      setTimeout(() => button.classList.remove("show"), 100 * i)
    })
  },

  onScroll : e => {
    if (window.pageYOffset > self.delta && self.navIsShown) {
      self.hideShownNav()
    }
  },

  scrollToId : e => {
    let link = e.currentTarget
    let id = link.getAttribute("href")
    let section = document.querySelector(id)

    e.preventDefault()
    st.animate(section.offsetTop, 1000)
  }
}
