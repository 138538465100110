"use strict"

import swiper from 'swiper';

let self

export default self = {

  /*
    Variables

  */
  containers : document.querySelectorAll(".slideshow"),
  instanceArray : [],




  /*
    Concrete Types

  */
  Types : {
    ThreeUp : {
      name : "three-up",
      slideContainer : null,
      instance : null,

      setup : instance => {
        self.Types.ThreeUp.instance = instance
        self.Types.ThreeUp.slideContainer = self.Types.ThreeUp.instance.container.querySelector(".slides")
        self.Types.ThreeUp.slideContainer.style.width = self.Types.ThreeUp.setContainerWidth()
        self.Types.ThreeUp.instance.inc = 1

        self.Types.ThreeUp.setTransformValue()
        window.addEventListener("resize", self.Types.ThreeUp.setTransformValue)

        instance.container.classList.remove("opaque")

        //self.Types.ThreeUp.swiperInit()
      },

      swiperInit : () => {
        var mySwiper = new Swiper (self.Types.ThreeUp.instance.container, {
          direction: 'horizontal',
          loop: false,
          nextButton: '#westbank-collection .btn-next',
          prevButton: '#westbank-collection .btn-prev',
          freemode : true
        })
      },

      setContainerWidth : () => {
        let margin = (window.innerWidth > 601) ? 100 : 25
        let totalSlides = self.Types.ThreeUp.instance.slides.length
        let slideWidth = self.Types.ThreeUp.instance.slides[0].clientWidth + margin
        let containerWidth = slideWidth * totalSlides

        return containerWidth + "px"
      },
      getInitalTransformValue : () => {
        let slideWidth = self.Types.ThreeUp.instance.slides[0].clientWidth
        let screenMid = window.innerWidth / 2
        let slideCenter = screenMid - (slideWidth/2)

        return "translate3d(" + slideCenter + "px,0,0)"
      },
      setTransformValue : () => {
        let margin = (window.innerWidth > 601) ? 100 : 25
        let slideWidth = self.Types.ThreeUp.instance.slides[0].clientWidth
        let slideWidthWithMargin = self.Types.ThreeUp.instance.slides[0].clientWidth + margin
        let activeSlideOrder = self.Types.ThreeUp.instance.inc
        let screenMid = window.innerWidth / 2
        let slideCenter = screenMid - (slideWidth/2)
        let value = -((slideWidthWithMargin*activeSlideOrder) - slideCenter)

        self.Types.ThreeUp.slideContainer.style["transform"] = "translate3d(" + value + "px,0,0)"
      },
      navigate : (e) => {
        let btn = e.currentTarget
        let type = (btn.className.search("prev") !== -1) ? "prev" : "next"

        self.setShowClass(type, self.Types.ThreeUp.instance)
        self.manageNavButtons(self.Types.ThreeUp.instance)
        self.Types.ThreeUp.setTransformValue()
      }
    },

    FullPanel : {
      name : "full-panel",
      galleryOverlay : document.getElementById("galleryOverlay"),
      thumbnailGrid : document.querySelector("#galleryOverlay .thumbnail-grid"),

      setup : instance => {
        self.Types.FullPanel.setIndexes(instance)
        self.Types.FullPanel.buildGalleryMenu(instance)
      },
      setIndexes : instance => {
        let i = 0

        Array.from(instance.slides).forEach( slide => {
          slide.setAttribute("data-index", i)
          i += 1
        })
      },
      buildGalleryMenu : instance => {
        let item
        let columnsPerRow = 3
        let i = 1

        Array.from(instance.slides).forEach( slide => {
          item = self.Types.FullPanel.createGalleryMenuItem(slide)
          self.Types.FullPanel.thumbnailGrid.querySelector(".col-wrap").appendChild(item)

          i = (i === columnsPerRow) ? i = 1 : i += 1
        })

        self.Types.FullPanel.registerGalleryMenuEvents(instance)
      },
      createGalleryMenuItem : slide => {
        let imageContainer = document.createElement("div")
            imageContainer.className = "image-container";

        let bgImage = document.createElement("div")
            bgImage.className = "bg-image"
            bgImage.style["background-image"] = slide.querySelector(".bg-image").style["background-image"]

        let slideTitle = slide.querySelector("aside .title").innerText
        let slideCaption = slide.querySelector("aside p").innerText

        let labelText = (slideTitle.length === 0) ? slideCaption.substring(0, 80) + '...' : slideTitle
        let label = document.createElement("span")
            label.className = "label"
            label.innerText = labelText

        let div = document.createElement("div")
            div.className = "col-3"
            div.setAttribute('data-index', slide.getAttribute('data-index'))
            div.setAttribute('data-category', slide.getAttribute('data-category'))

        imageContainer.appendChild(bgImage)
        div.appendChild(imageContainer)
        div.appendChild(label)

        return div
      },
      registerGalleryMenuEvents : instance => {
        let galleryMenuItems = self.Types.FullPanel.thumbnailGrid.querySelectorAll(".col-3");

        Array.from(galleryMenuItems).forEach( item => {
          item.addEventListener("click", self.goto.bind(null, instance))
        })
      }
    }
  },




  /*
    Helper Methods

  */
  init : () => Array.from(
    self.setupInstances()
  ).forEach( instance =>
    self.initInstance(instance)
  ),

  setupInstances : () => {
    let arr = []
    let args = null

    Array.from(self.containers).forEach( container => {
      args = {
        inc : 0,
        container : container,
        type : container.getAttribute("data-type"),
        slides : container.querySelectorAll(".slide"),
        nav : container.querySelectorAll(".btn-prev, .btn-next")
      }

      arr.push(args)
    })

    return arr
  },

  initInstance : instance => {
    switch (instance.type) {
      case self.Types.ThreeUp.name:
        self.Types.ThreeUp.setup(instance)
        Array.from(instance.nav).forEach(btn => btn.addEventListener("click", self.Types.ThreeUp.navigate))
      break
      case self.Types.FullPanel.name:
        self.Types.FullPanel.setup(instance)
        Array.from(instance.nav).forEach(btn => btn.addEventListener("click", self.navigate.bind(null, instance)))
      break
    }
  },

  setIncrementValue : (type, instance) => {
    let newInc

    if (type === "prev") {
      newInc = (instance.slides[instance.inc - 1]) ? instance.inc - 1 : instance.slides.length - 1
    } else if (type === "next") {
      newInc = (instance.slides[instance.inc + 1]) ? instance.inc + 1 : 0
    }

    return newInc
  },

  setShowClass : (type, instance) => {
    instance.slides[instance.inc].classList.remove("show")
    instance.inc = self.setIncrementValue(type, instance)
    instance.slides[instance.inc].classList.add("show")
  },

  manageNavButtons : instance => {
    if (instance.inc === 0) {
      instance.nav[0].classList.add("hidden")
    } else {
      instance.nav[0].classList.remove("hidden")
    }

    if (instance.inc === instance.slides.length-1) {
      instance.nav[1].classList.add("hidden")
    } else {
      instance.nav[1].classList.remove("hidden")
    }
  },

  navigate : (instance, e) => {
    let btn = e.currentTarget
    let type = (btn.className.search("prev") !== -1) ? "prev" : "next"

    self.setShowClass(type, instance)
  },

  goto : (instance, e) => {
    let index = e.currentTarget.getAttribute('data-index')
    let newSlide = instance.slides[index]

    instance.slides[instance.inc].classList.remove("show")
    instance.inc = index
    instance.slides[instance.inc].classList.add("show")

    document.querySelector('header .btn-close').click()
  }

}
