'use strict'

import Header from './header'

let self

export default self = {
  container : document.querySelector('body.home'),
  homeCover : document.getElementById('homeCover'),

  headerFooterShown : false,
  videoDuration : null,
  triggerHeaderFooter : null,
  panelInc : 0,

  init : () => {
    Header.setupBodyMovin()
    self.setupElements()
    self.registerEvents()
    self.setLandingVideo()
    self.startSequence()
  },

  setupElements : () => {
    self.homeCoverContent = self.homeCover.querySelector('.content')
    self.headerNav = document.querySelector('#mainHeader nav')
    self.footer = document.getElementById('mainFooter')
    self.introAnimation = document.querySelector('.intro-animation')
    self.introPanels = document.querySelectorAll('.intro-animation .panel')
    self.landingVideo = document.getElementById('landingVideo')
  },

  registerEvents : () => {
    self.landingVideo.addEventListener("timeupdate", self.onTimeUpdate)
  },

  startSequence : () => {
    self.cyclePanel()

    setTimeout( () => {
      Header.loadingBmObj.play()
      self.landingVideo.play()
    }, 3000)
  },

  cyclePanel : () => {
    let panel = self.introPanels[self.panelInc]

    if (panel.classList.contains("show")) {
      panel.classList.remove("show")
      self.panelInc += 1
      panel = self.introPanels[self.panelInc]
    }

    setTimeout( () => panel.classList.add("show"), 500)

    if (self.introPanels[self.panelInc+1]) {
      setTimeout(self.cyclePanel, 3300)
    }
  },

  setLandingVideo : () => {
    const dir = '/app/themes/butterfly/assets/vid/'
    const file = (window.innerWidth > 768) ? 'home-video.mp4' : 'home-video-mobile.mp4'

    self.landingVideo.src = dir + file
  },

  onTimeUpdate : e => {

    // Set video triggers on first pass of this function
    if (!self.videoDuration) self.setVideoTriggerTimes()

    // Show the header/footer
    if( self.landingVideo.currentTime > self.triggerHeaderFooter && !self.headerFooterShown ) {
      self.headerFooterShown = true
      self.headerNav.classList.remove("offcanvas")
      document.querySelector('.scroll-indicator').classList.remove("hide")
    }

  },

  setVideoTriggerTimes : () => {
    self.videoDuration = self.landingVideo.duration
    self.triggerHeaderFooter = self.videoDuration - (self.videoDuration + 3)
    self.introPanels[self.panelInc].classList.add("show")
  }

}
