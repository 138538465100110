'use strict'

let self

export default self = {
  container : document.getElementById('register'),
  form : document.getElementById('contact-form'),
  optInInput : document.getElementById('VH_Email_Opt_In__c'),
  //langSelect : document.getElementById('Preferred_Language__c'),
  workingEmployer : document.getElementById('workingEmployer'),
  realtorEmployer : document.getElementById('realtorEmployer'),
  employerInput : document.getElementById('Employer'),
  radios : null,
  requiredInputs: null,
  emailRegex : /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,

  init : () => {
    self.setupElements()
    self.registerEvents()
    self.setReferrerField()
  },

  setupElements : () => {
    self.radios = self.container.querySelectorAll('input[type="radio"]')
    self.requiredInputs = self.container.querySelectorAll('input.required')
  },

  registerEvents : () => {
    //self.langSelect.addEventListener("change", self.onChange)
    Array.from( self.radios ).forEach( radio => radio.addEventListener("click", self.onRadioSelect) )
    if (self.workingEmployer) self.workingEmployer.addEventListener("keyup", self.populateEmployer)
    self.realtorEmployer.addEventListener("keyup", self.populateEmployer)
    self.form.addEventListener("submit", self.onSubmit)
  },

  populateEmployer : e => {
    self.employerInput.value = e.currentTarget.value
    console.log(self.employerInput.value);
  },

  onChange : e => {
    let option = e.currentTarget.options[e.currentTarget.selectedIndex]

    document.querySelector('input[name="preferred_language__c"]').value = option.value
  },

  onRadioSelect : e => {
    let name = e.currentTarget.name
    let val = e.currentTarget.value
    let container = self.container.querySelector(`.column[rel="${name}"]`)

    if (val === "Yes") {
      container.classList.remove("hidden")
      self.hideOppositeGroup(name)
    } else {
      container.classList.add("hidden")
    }
  },

  onSubmit : e => {
    let blankFormInputs = Array.from(self.requiredInputs).map(self.checkFormInputs)

    for (let input of blankFormInputs) {
      if (input) {
        e.preventDefault()
        console.log(`Validation Error: Form element with name ${input.name}`)
        input.parentNode.classList.add('error')
      }
    }

    if (!self.optInInput.checked) {
      e.preventDefault()
      self.optInInput.parentNode.classList.add('error')
    }

  },

  checkFormInputs : input => {

    // Input needs to have length
    if (input.value.length === 0) return input

    // Email needs to be valid
    if (input.name === 'Email__c' && !self.emailRegex.test(input.value)) return input

    return null

  },

  hideOppositeGroup : (name) => {
    let no

    if (name === 'Are_you_a_realtor__c') {
      no = self.container.querySelector(`.column[rel="Cumulus1__Working_with_Other_Realtors__c"]`).parentNode.querySelector('input[value="No"]')
    } else if (name === 'Cumulus1__Working_with_Other_Realtors__c') {
      no = self.container.querySelector(`.column[rel="Are_you_a_realtor__c"]`).parentNode.querySelector('input[value="No"]')
    }

    no.click()
  },

  setReferrerField : () => document.querySelector('input[name="Referring_Website__c"]').value = document.referrer
}
