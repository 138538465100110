'use strict'
import matrixData from '../../floorplans/data/v1_The_Butterfly_Atypical_Plans_Asset_Matrix.json'

let self

export default self = {
  container : document.getElementById('floorplans'),
  assetDir : '/app/themes/butterfly/assets/floorplans',
  data : matrixData,

  keys : {
    unitNumber : "Home #",
    unitType : "Home Type",
    floor : "Level",
    totalArea : "Total Sq Ft"
  },

  init : () => {
    self.createUnitsFromJSON()
    self.setupElements()
    self.registerEvents()

    setTimeout( () => self.sidebarButtons[2].click(), 500)
  },

  createUnitsFromJSON : () => {
    let totalArea
    const plansContainer = document.querySelector('.results .plans')
    const category = "Home"

    Array.from( self.data ).forEach( unit => {
      const totalArea = unit[self.keys.totalArea].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      const floorRange = self.getFloorRange(unit[self.keys.floor])

      const unitNumber = document.createElement("span")
            unitNumber.className = "unit-number"
            unitNumber.innerText = `${unit[self.keys.unitNumber]} ${category}`

      const floors = document.createElement("span")
            floors.className = "floors"
            floors.innerText = `${unit[self.keys.floor]}th Floor`

      const unitType = document.createElement("span")
            unitType.className = "unit-type"
            unitType.innerText = unit[self.keys.unitType]

      const unitTotalArea = document.createElement("span")
            unitTotalArea.className = "unit-total-area"
            unitTotalArea.innerText = `${totalArea} square feet (total)`

      const planImage = document.createElement("img")
            planImage.src = `${self.assetDir}/plans/${category}_Floorplans-${unit[self.keys.unitNumber]}.svg`

      const planMeta = document.createElement("div")
            planMeta.className = "plan-meta"
            planMeta.appendChild(unitNumber)
            planMeta.appendChild(floors)
            planMeta.appendChild(unitType)
            planMeta.appendChild(unitTotalArea)

      const wrap = document.createElement("div")
            wrap.className = "wrap"
            wrap.appendChild(planImage)

      const button = document.createElement('button')
            button.setAttribute("data-orientation", self.getOrientationDirection(unit[self.keys.unitNumber]) )
            button.setAttribute("data-category", category )
            button.setAttribute("data-unit-number", unit[self.keys.unitNumber] )
            button.setAttribute("data-floor-range", floorRange )


            // NOTE: to hide the atypical 02 units from the results, as of right now, if anything from 14-39 is in the JSON it's not to be shown in the results grid but needs to be created to be linked to.
            if (floorRange === "14-39") {
              button.setAttribute("data-hidden", "" )
            } else {
              button.setAttribute("data-floor", unit[self.keys.floor] )
            }

            button.appendChild(planMeta)
            button.appendChild(wrap)

      plansContainer.appendChild(button)
    })
  },

  setupElements : () => {
    self.pageTitle = document.querySelector('.page-title')
    self.plateUnitGroups = document.querySelectorAll('.unit-group')
    self.sidebar = self.container.querySelector('.sidebar')
    self.results = self.container.querySelector('.results')
    self.plateContainer = self.container.querySelector('.plate-container')
    self.sidebarButtons = self.sidebar.querySelectorAll('button')
    self.resultsButtons = self.results.querySelectorAll('button')
    self.floorRangeButtons = self.sidebar.querySelectorAll('.floors-group.has-multiple .floors')
    self.plateGroupLinks = self.plateContainer.querySelectorAll('.plate-group nav .link')

    self.overlay = document.getElementById('floorplansOverlay')
    self.btnClose = self.overlay.querySelector('.btn-close')
    self.ovlUnitNumber = self.overlay.querySelector('.unit-number')
    self.ovlFloors = self.overlay.querySelector('.floors')
    self.ovlUnitType = self.overlay.querySelector('.unit-type')
    self.ovlUnitTotalArea = self.overlay.querySelector('.unit-total-area')
    self.ovlDownload = self.overlay.querySelector('.download')
    self.ovlPlanImage = self.overlay.querySelector('.images img')
    self.ovlPlateContainer = self.overlay.querySelector('.plate-container')
    self.ovlViewImg = self.overlay.querySelector('.view-img')
    self.ovlViewSelectors = self.overlay.querySelectorAll('.view-selector select')
    self.ovlAltSelectorContainer = self.overlay.querySelector('.alt-selector')
    self.ovlAltSelector = self.overlay.querySelector('.alt-selector select')
  },

  registerEvents : () => {
    Array.from( self.sidebarButtons ).forEach( button => button.addEventListener("click", self.changeCategory))
    Array.from( self.resultsButtons ).forEach( button => button.addEventListener("click", self.toggleOverlay))
    Array.from( self.plateUnitGroups ).forEach( group => group.addEventListener("click", self.changeUnitFromPlate))
    Array.from( self.floorRangeButtons ).forEach( button => button.addEventListener("click", self.changeFloorRange))
    Array.from( self.plateGroupLinks ).forEach( button => button.addEventListener("click", self.changePlateFromGroup))
    Array.from( self.ovlViewSelectors ).forEach( select => select.addEventListener("change", self.toggleViewImage))

    self.btnClose.addEventListener("click", self.toggleOverlay)
    self.ovlAltSelector.addEventListener("change", self.showAltPlan)
    window.addEventListener("resize", self.onResize)

    $("#floorplansOverlay .view-img").cyclotron();
  },

  onResize : e => {

    // Adjust the view center
    if (window.innerWidth < self.viewMaxWidth) self.centerViewOnResize()

    // Re calculate the sidebar filter height
    const activeSidebarButton = self.sidebar.querySelector('.category.active button')
    self.openSelectedCategory(activeSidebarButton, true)

  },










  /*

    Sidebar

  */
  changeCategory : e => {
    if (e.currentTarget.parentNode.classList.contains("active")) return false

    const button = e.currentTarget
    const newCategory = button.getAttribute("data-category")
    const activeCategory = self.sidebar.querySelector('.category.active')
    const newFloorLabel = button.querySelector('.floors-group .floors:first-child')
    const floorRange = newFloorLabel.getAttribute("data-floor-range")

    //
    self.pageTitle.innerText = `${newCategory}s`

    //
    if (activeCategory) self.closeActiveCategory(activeCategory)
    self.openSelectedCategory(button)

    //
    self.changePlateGroup(newCategory)

    //
    if (activeCategory) activeCategory.querySelector('.floors-group .floors.active').classList.remove("active")
    newFloorLabel.classList.add("active")

    //
    Array.from( self.resultsButtons ).forEach( button => {
      if (floorRange) {
        let cat = button.getAttribute("data-category")
        let range = button.getAttribute("data-floor-range")
        let keepHidden = button.getAttribute("data-hidden")

        if (cat === newCategory && range === floorRange && keepHidden === null) {
          button.classList.add('show')
        } else {
          button.classList.remove('show')
        }
      } else {
        if (button.getAttribute("data-category") === newCategory) {
          button.classList.add('show')
        } else {
          button.classList.remove('show')
        }
      }
    })

  },

  changePlateGroup : (category, floorRange) => {
    const activePlateGroup = self.plateContainer.querySelector(`.plate-group.show`)
    const plateGroupSelector = (floorRange) ? `.plate-group[rel="${category}"][data-floor-range="${floorRange}"]` : `.plate-group[rel="${category}"]` ;
    const newPlateGroup = self.plateContainer.querySelector(plateGroupSelector)

    if (activePlateGroup) activePlateGroup.classList.remove("show")
    newPlateGroup.classList.add("show")
  },

  changePlateFromGroup : e => {
    const link = e.currentTarget
    const floor = link.getAttribute("data-floor")
    const plateGroup = link.parentNode.parentNode
    const floorRange = plateGroup.getAttribute("data-floor-range")

    if (link.parentNode.querySelector('.link.active')) {
      link.parentNode.querySelector('.link.active').classList.remove("active")
    }
    link.classList.add("active")

    if (plateGroup.querySelector('.plate.show')) {
      plateGroup.querySelector('.plate.show').classList.remove("show")
    }
    plateGroup.querySelector(`.plate[data-floor="${floor}"]`).classList.add("show")

    self.filterResultsByFloor(floor, floorRange)
  },

  filterResultsByFloor : (floor, floorRange) => {
    Array.from( self.resultsButtons ).forEach( button => {
      const btnRange = button.getAttribute("data-floor-range")
      const btnFloor = button.getAttribute("data-floor")

      if (!floor) return

      if ( btnFloor === floor ) {
        button.classList.add('show')
      } else {
        button.classList.remove('show')
      }
    })
  },

  openSelectedCategory : (button, resizing)  => {
    const parent = button.parentNode
    const content = parent.querySelector('.content')
    const contentHeight = parent.querySelector('.wrap').clientHeight
    const isOpen = parent.classList.contains("active") && !resizing

    parent.classList.add("active")

    if (isOpen) content.removeAttribute("style")
    if (!isOpen) content.style.height = `${contentHeight}px`
  },

  closeActiveCategory : parent => {
    const content = parent.querySelector('.content')

    content.removeAttribute("style")
    parent.classList.remove("active")
  },

  changeFloorRange : e => {
    const span = e.currentTarget
    const parent = span.parentNode.parentNode.parentNode

    if ( !parent.classList.contains("active") ) return false

    const button = span.parentNode.parentNode
    const selectedRange = span.getAttribute("data-floor-range")
    const selectedCat = button.getAttribute("data-category")

    self.changePlateGroup(selectedCat, selectedRange)
    self.sidebar.querySelector('.floors-group.has-multiple .floors.active').classList.remove("active")
    span.classList.add("active")

    Array.from( self.resultsButtons ).forEach( button => {
      let cat = button.getAttribute("data-category")
      let range = button.getAttribute("data-floor-range")
      let keepHidden = button.getAttribute("data-hidden")

      if (cat === selectedCat && range === selectedRange && keepHidden === null) {
        button.classList.add('show')
      } else {
        button.classList.remove('show')
      }
    })

    const newPlateGroupNav =  self.plateContainer.querySelector('.plate-group.show nav')

    if (newPlateGroupNav) {
      newPlateGroupNav.querySelector('.link').click()
    }
  },

  getFloorRange : floor => {
    switch (floor) {
      case 5 :
        return '5'
      break;

      case 6 :
      case 7 :
      case 8 :
      case 9 :
      case 10 :
        return '6-10'
      break;

      case 11 :
      case 12 :
      case 13 :
        return '11-13'
      break;

      default :
        return '14-39'
    }
  },

  getOrientationDirection : unitNumber => {
    const num = unitNumber.toString().substr(-2)

    switch (num) {
      case '07' :
        return 'N'
      break;
      // case  :
      //   return 'NE'
      // break;
      case '08' :
        return 'E'
      break;
      case '01' :
        return 'SE'
      break;
      // case  :
      //   return 'S'
      // break;
      case '02' :
        return 'SW'
      break;
      case '03' :
      case '05' :
        return 'W'
      break;
      case '04' :
      case '06' :
        return 'NW'
      break;
    }
  },







  /*

    Overlay

  */
  toggleOverlay : e => {
    const button = e.currentTarget
    const isOpen = self.overlay.classList.contains("show")

    self.overlay.classList.toggle("show")
    document.documentElement.classList.toggle("no-scroll")

    if (!isOpen) self.populateOverlay(button)
    if (isOpen) self.unPopulateOverlay()
  },

  populateOverlay : button => {
    const unitNumber = button.querySelector('.unit-number').innerText
    const floorText = button.querySelector('.floors').innerText
    const unitType = button.querySelector('.unit-type').innerText
    const unitTotalArea = button.querySelector('.unit-total-area').innerText
    const category = button.getAttribute("data-category")
    const isolatedUnitNumber = button.getAttribute("data-unit-number")
    const floorRange = button.getAttribute("data-floor-range")
    const floor = button.getAttribute("data-floor")
    const is02Home = unitNumber.toUpperCase().search('02 HOME') !== -1

    if (is02Home) self.toggleAltPlanSelector("add")

    self.toggleViewSelectorCategory("add", `.category[rel="${category}"]`, floorRange)
    self.setViewOffset(button)

    self.ovlUnitNumber.innerText = unitNumber
    self.ovlFloors.innerText = floorText
    self.ovlUnitType.innerText = unitType
    self.ovlUnitTotalArea.innerText = unitTotalArea
    self.ovlDownload.setAttribute("href", `${self.assetDir}/pdfs/The Butterfly ${category} ${isolatedUnitNumber}.pdf`)
    self.ovlPlanImage.setAttribute("src", `${self.assetDir}/plans/${category}_Floorplans-${isolatedUnitNumber}.svg`)

    if (floor) {
      self.ovlPlateContainer.querySelector(`.plate[rel="${category}"][data-floor="${floor}"]`).classList.add("show")
    } else if (floorRange === "14-39") {
      self.ovlPlateContainer.querySelector(`.plate[rel="${category}"][data-floor="${floorRange}"]`).classList.add("show")
    } else {
      self.ovlPlateContainer.querySelector(`.plate[rel="${category}"]`).classList.add("show")
    }

    //
    let unitInPlate = self.ovlPlateContainer.querySelector(`svg .unit-group[data-category="${category}"][data-unit-number="${isolatedUnitNumber}"]`)
    console.log( `category: ${category}, isolatedUnitNumber: ${isolatedUnitNumber}, unitInPlate: ${unitInPlate}` )
    if ( !unitInPlate ) {
      unitInPlate = self.ovlPlateContainer.querySelector(`svg .unit-group[data-category="${category}"][data-unit-number="02"]`)
    }
    unitInPlate.classList.add("active")

  },

  unPopulateOverlay : () => {
    self.toggleViewSelectorCategory("remove", `.category.show`)
    self.ovlUnitNumber.innerText = ""
    self.ovlFloors.innerText = ""
    self.ovlUnitType.innerText = ""
    self.ovlUnitTotalArea.innerText = ""
    self.ovlDownload.setAttribute("href", "")
    self.ovlPlanImage.setAttribute("src", "")
    self.ovlPlateContainer.querySelector('.plate.show').classList.remove("show")
    self.toggleAltPlanSelector("remove")
    self.ovlPlateContainer.querySelector(`svg .unit-group.active`).classList.remove("active")
  },

  toggleAltPlanSelector : action => self.ovlAltSelectorContainer.classList[action]("show"),

  changeUnitFromPlate : e => {
    const el = e.currentTarget
    const category = el.getAttribute('data-category')
    const unitNumber = el.getAttribute('data-unit-number')
    const button = self.results.querySelector(`button[data-category="${category}"][data-unit-number="${unitNumber}"]`)

    if (self.overlay.classList.contains("show")) self.btnClose.click()
    setTimeout( () => button.click(), 10)
  },

  showAltPlan : e => {
    const el = e.currentTarget
    const options = el.options
    const value = options[el.selectedIndex].value
    const button = self.results.querySelector(`button[data-unit-number="${value}"]`)

    if (self.overlay.classList.contains("show")) self.btnClose.click()
    setTimeout( () => button.click(), 10)
  },


  setViewOffset : button => {
    const viewDirection = button.getAttribute("data-orientation")
    const viewPosition = self.getViewPosition(viewDirection)

    self.ovlViewImg.style.backgroundPosition = `${viewPosition}px center`
  },

  toggleViewSelectorCategory : (fn, selector, floorRange) => {
    let targetOption
    const el = self.overlay.querySelector(selector)
    const select = el.querySelector('select')

    el.classList[fn]('show')

    if (fn === 'add') {
      if (floorRange === '5') {
        targetOption = select.options[0]
      } else if (floorRange === '6-10') {
        targetOption = select.options[1]
      } else if (floorRange === '11-13') {
        targetOption = select.options[2]
      } else {
        targetOption = select.options[select.options.length-1]
      }

      select.value = targetOption.value
      self.toggleViewImage(null, select)
    }
  },

  toggleViewImage : (e, select) => {
    const el = (e) ? e.currentTarget : select
    const options = el.options
    const value = options[el.selectedIndex].value
    const path = `/app/themes/butterfly/assets/floorplans/views/${value}-ft-adjusted-web.jpg`

    self.ovlViewImg.style.backgroundImage = `url(${path})`
  },

  viewMaxWidth : 1024 + (1024 * 0.10),

  centerViewOnResize : () => {
    let diff = (self.viewMaxWidth - window.innerWidth) / 10
    let curr = Number(self.ovlViewImg.style.backgroundPosition.replace("px center", ""))
    let val = (self.lastWindowWidth > window.innerWidth) ? curr - diff : curr + diff

    self.ovlViewImg.style.backgroundPosition = `${val}px center`
    self.lastWindowWidth = window.innerWidth
  },

  getViewPosition : (dir) => {
    switch (dir) {
      case "N":
        return -1356
      break
      case "NE":
        return 1763
      break
      case "E":
        return 1100
      break
      case "SE":
        return 818
      break
      case "S":
        return 382
      break
      case "SW":
        return -110
      break
      case "W":
        return -562
      break
      case "NW":
        return -889
      break
      default:
        return 0
    }
  }



}
