'use strict'

let self

export default self = {
  container : document.getElementById('accordion'),

  init : () => {
    self.setupElements()
    self.registerEvents()
  },

  setupElements : () => {
    self.titles = self.container.querySelectorAll('article .accordion-title')
  },

  registerEvents : () => {
    Array.from( self.titles ).forEach( title => title.addEventListener("click", self.toggleAccordionItem) )
  },

  toggleAccordionItem : e => {
    const parent = e.currentTarget.parentNode
    const contentContainer = parent.querySelector('.accordion-content')
    const contentHeight = parent.querySelector('.accordion-content-wrap').clientHeight

    parent.classList.toggle("open")

    if (contentContainer.getAttribute('style')) {
      contentContainer.removeAttribute('style')
    } else {
      contentContainer.style.height = `${contentHeight}px`
    }
  }
}
